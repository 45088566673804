import React, {useState, useCallback, useMemo} from 'react';
import {useNavigate, useLocation, Link} from 'react-router-dom';
import {Alert, Form, Input, Button, Checkbox, AlertProps} from 'antd';
import {useTranslation} from 'react-i18next';
import queryString from 'query-string';
import {AxiosError} from 'axios';

import {useLogin} from '../../queries/useLogin';
import LanguageSelect from '../../components/MenuWrapper/components/LanguageSelect';
import i18n from '../../i18n';

type LoginForm = {
	email: string;
	password: string;
	rememberUser: boolean;
};

const Login = () => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const {search} = useLocation();
	const loginPost = useLogin();

	const {action} = queryString.parse(search);

	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const onFormSubmit = useCallback(({email, password, rememberUser}: LoginForm) => {
		setLoading(true);
		loginPost.mutate({email, password}, {
			onSuccess: async (result) => {
				setLoading(false);
				if (result.data.authorized) {
					if (result.data.userLanguage) {
						await i18n.changeLanguage(result.data.userLanguage);
					}
					// By setting loggedIn to localStorage, JWTValidator component will redirect to the main page
					if (rememberUser) {
						localStorage.removeItem('logInExpires');
						return localStorage.setItem('loggedIn', 'true');
					} else {
						// Expires in one hour
						localStorage.setItem('logInExpires', (Date.now() + 60 * 60 * 1000).toString());
						return localStorage.setItem('loggedIn', 'true');
					}
				}
				return setError(t('errors.something-went-wrong'));
			},
			onError: (error) => {
				setLoading(false);
				if (error instanceof AxiosError) {
					if (error.response?.status === 403) {
						const {message} = error.response.data;
						if (message === 'User does not have any accounts') {
							return setError(t('login.errors.user-no-any-accounts'));
						} else if (message === 'User is not active') {
							return setError(t('login.errors.user-not-active'));
						}
						return setError(t('login.errors.invalid-credentials'));
					}
					if (error.response?.status === 429) {
						return setError(t('login.errors.too-many-requests'));
					}
				}
				return setError(t('errors.something-went-wrong'));
			},
		});
	}, [setLoading, setError, loginPost, t]);

	const alert: AlertProps | null = useMemo(() => {
		if (action === 'reset_password') {
			return {
				type: 'info',
				message: t('login.password-reset-email-sent'),
			};
		}
		if (action === 'new_password_set') {
			return {
				type: 'info',
				message: t('login.password-reset-successful'),
			};
		}
		if (action === 'user_created') {
			return {
				type: 'info',
				message: t('login.register.user-created-info'),
			};
		}
		if (action === 'user_activated') {
			return {
				type: 'info',
				message: t('login.activate-user.user-activated'),
			};
		}
		return null;
	}, [action, t]);

	const onCreateNewAccount = useCallback(() => {
		navigate('/create-account');
	}, [navigate]);

	return (
		<div className={'login__page'}>
			<div className={'login__languageselect'}>
				<LanguageSelect />
			</div>

			<Form className={'login'} onFinish={onFormSubmit}>
				<div className={'login__header'}>
					<img src={'/kululaskut_logo.png'} />
				</div>

				<div className={'login__form'}>

					{alert && <Alert message={alert.message} type={alert.type} showIcon />}
					{error && <Alert message={error} type={'error'} showIcon />}

					<Form.Item name={'email'} required={true}>
						<Input
							placeholder={t('login.email') as string}
							type={'email'}
						/>
					</Form.Item>

					<Form.Item name={'password'} required={true}>
						<Input
							placeholder={t('login.password') as string}
							type={'password'}
						/>
					</Form.Item>


					<Form.Item valuePropName={'checked'} name={'rememberUser'} className={'login__form__checkbox'}>
						<Checkbox>
							{t('login.remember-user')}
						</Checkbox>
					</Form.Item>
				</div>

				<div className={'login__footer'}>
					<Form.Item name={'submit'}>
						<Button
							type={'primary'}
							htmlType={'submit'}
							className={'login__footer__button'}
							loading={loading}
							size={'large'}
						>
							{t('login.login')}
						</Button>
					</Form.Item>

					<Link
						className={'login__footer__link'}
						to={'/reset-password'}
					>
						{t('login.forgot-password-link')}
					</Link>

					<Button
						type={'primary'}
						size={'large'}
						className={'login__footer__newaccount__button'}
						onClick={onCreateNewAccount}
						ghost
					>
						{t('login.create-account')}
					</Button>
				</div>
			</Form>
		</div>
	);
};

export default Login;